import React, { Component } from 'react';
import classes from './index.module.css';
import logo from '../images/Classplus-logo.svg';
import Splash from './Splash';
import sound from '../images/audio.mp3';
class HelloScreen extends Component {
	constructor() {
		super();
		this.state = {
			isClicked: false,
			name: '',
			disableState: true
		};
	}
	handleNameChange = (e) => {
		this.setState(
			{
				name: e.target.value
			},
			() => {
				if (this.state.name !== '') this.setState({ disableState: false });
			}
		);
	};

	handleClick = () => {
		localStorage.setItem('name', this.state.name);
		if (this.state.name !== '') this.setState({ isClicked: true });
	};

	render() {
		const { isClicked, name, disableState } = this.state;
		return isClicked ? (
			<div>
				{/* <audio src={sound} autoPlay /> */}
				<Splash name={name} />
			</div>
		) : (
			<div className={classes.HelloScreenContainer}>
				<div className={classes.HelloScreenHeader}>{/* <img src={back} alt="arrow"/> */}</div>
				<div className={classes.HelloScreenLogo}>
					<img src={logo} alt="logo" />
				</div>
				<div className={classes.HelloScreenContent}>
					<p className={classes.HelloScreenContentHeading}>Hello!</p>
					<p>Enter your Coaching Institute’s name to step into the future</p>
					<input
						type="text"
						placeholder="Enter your Coaching Institute’s name"
						value={name}
						onChange={this.handleNameChange}
						className={classes.HelloScreenContentNameInput}
					/>
					<button
						className={disableState ? classes.HelloScreenDisable : classes.HelloScreenContentButton}
						onClick={this.handleClick}
					>
						Start Now
					</button>
				</div>
			</div>
		);
	}
}

export default HelloScreen;

import React, { Component } from 'react';
import classes from './index.module.css';
// import back from "../images/back.svg";
import myInstitute from '../images/MyInstitute.svg';
// import HelloScreen from "../HelloScreen/HelloScreen";
import Intro from './Intro';
import sound from '../images/audio.mp3';
class Splash extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isBackClicked: false,
			isSplashClicked: false
		};
	}

	componentDidMount() {
		const nam = localStorage.getItem('name');
		this.setState({ name: nam });
		setTimeout(() => {
			this.setState({ isSplashClicked: true });
		}, 1500);
	}

	// handleBackClick=()=>{
	//     this.setState({isBackClicked:true})
	// }

	// handleSplashClick=()=>{
	//     this.setState({isSplashClicked:true})
	// }

	render() {
		const { isSplashClicked, name } = this.state;
		// const { name } = this.props;
		// const { name } = localStorage.getItem('name');
		return (
			// isBackClicked ?
			// <HelloScreen/>
			// :
			isSplashClicked ? (
				<Intro name={this.props.name} />
			) : (
				<div className={classes.SplashContainer}>
					{/* <audio src={sound} autoPlay /> */}
					{/* <button className={classes.SplashHeader} onClick={this.handleBackClick}>
              <img src={back} alt="arrow"/>
            </button> */}
					<div
						className={classes.SplashContent}
						// onClick={this.handleSplashClick}
					>
						<img src={myInstitute} alt="myInstitute" className={classes.SplashmyInstitute} />
						<p className={classes.SplashName}>Welcome to </p>
						<p className={classes.SplashName}>{name}</p>
					</div>
				</div>
			)
		);
	}
}

export default Splash;

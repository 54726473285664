import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
import backgroundSub from '../images/Illustration1.svg';
import background from '../images/growth.webp';
// import Image from "react-image-webp";
import Teach from './Teach';
import Description from './Description';
import Revenue from './Revenue';
import arrowUp from '../images/arrowUp.svg';
import { Swipeable } from 'react-swipeable';
import LazyImage from './LazyImage';
import brandLogo from '../images/brandLogo.svg';
class Brand extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextClick = () => {
		this.setState({ isNextClicked: true });
	};

	render() {
		const { isBackClicked, isNextClicked } = this.state;
		return isBackClicked ? (
			<Teach />
		) : isNextClicked ? (
			<Description />
		) : (
			<Swipeable
				onSwipedUp={() => {
					this.handleNextClick();
				}}
			>
				<div className={classes.BrandContainer}>
					<div className={classes.BrandInnerContainer}>
						<div className={classes.BrandHeader}>
							<button className={classes.BrandBackButton} onClick={this.handleBackClicked}>
								<img src={back} alt="back" />
							</button>
							{/* <button className={classes.BrandTellMore}>Tell me more</button> */}
						</div>

						<div className={classes.BrandContent}>
							<p className={classes.BrandTopText}>You will not be just a Name</p>
							<p className={classes.BrandSecondText}>You will be a Digital</p>
							<img src={brandLogo} alt="brandLogo" style={{ marginBottom: '32px' }} />
							<embed
								src="https://clevertapimages.s3.ap-south-1.amazonaws.com/0001/WhatsApp+Video+2020-07-21+at+19.45.38+(2).mp4"
								width="90%"
								height="200px"
							/>
							<p className={classes.BrandVideoText}>Watch this video to know more</p>
						</div>

						<div className={classes.BrandContainerFooter} onClick={this.handleNextClick}>
							<div class={classes.BrandBounce}>
								<img width="24" height="24" alt="arrow" src={arrowUp} />
							</div>
							<p style={{ margin: '0px', paddingTop: '16px' }}>Swipe up to see more</p>
						</div>
					</div>
				</div>
			</Swipeable>
		);
	}
}

export default Brand;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
// import Image from "react-image-webp";
const ImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 50vw;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: inherit;
  }
  50% {
    background-color: inherit;
  }
  100% {
    background-color: inherit;
  }
`;

const Placeholder = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
	position: absolute;
	left: 0;
	height: 100%;
`;

const LazyImage = ({ src, alt, color, isIntro }) => {
	const refPlaceholder = React.useRef();

	const removePlaceholder = () => {
		refPlaceholder.current.remove();
	};

	return (
		<ImageWrapper>
			<Placeholder ref={refPlaceholder} color={color} />
			<LazyLoad>
				<StyledImage
					onLoad={removePlaceholder}
					onError={removePlaceholder}
					src={src}
					alt={alt}
					style={isIntro ? { height: 'inherit' } : { height: '100%' }}
				/>
			</LazyLoad>
		</ImageWrapper>
	);
};

LazyImage.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
};

export default LazyImage;

import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
import backgroundSub from '../images/Illustration3.png';
import background from '../images/engage.webp';
// import Image from "react-image-webp";
import Revenue from './Revenue';
import Teach from './Teach';
import arrowUp from '../images/arrowUp.svg';
import { Swipeable } from 'react-swipeable';
import LazyImage from './LazyImage';
import map from '../images/map.gif';
class Engagement extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextClick = () => {
		this.setState({ isNextClicked: true });
	};

	render() {
		const { isBackClicked, isNextClicked } = this.state;
		return isBackClicked ? (
			<Revenue />
		) : isNextClicked ? (
			<Teach />
		) : (
			<Swipeable
				onSwipedUp={() => {
					this.handleNextClick();
				}}
			>
				<div className={classes.EngagementContainer}>
					<div className={classes.EngagementInnerContainer}>
						<div className={classes.EngagementHeader}>
							<button className={classes.EngagementBackButton} onClick={this.handleBackClicked}>
								<img src={back} alt="back" />
							</button>
							{/* <button className={classes.EngagementTellMore}>Tell me more</button> */}
						</div>
						<div className={classes.EngagementBackgroudImg}>
							{/* <img className={classes.EngagementMainBg} src={backgroudMain} /> */}
							{/* <Image className={classes.EngagementSubBg}
                    src={backgroundSub}
                    webp={background}
                    /> */}
							{/* <LazyImage
								className={classes.EngagementSubBg}
								src={backgroundSub}
								webp={background}
								// color={rgba(114, 175, 175, 0.16)}
			  /> */}

							{/* <img src={map} className={classes.EngagementMap} /> */}
							<img src={backgroundSub} style={{ width: '100%' }} />
						</div>
						{/* <div className={classes.EngagementContainerBody}>
                  <p className={classes.EngagementBodyHeader}>Engagement Multiplied</p>
                  <p className={classes.EngagementDescription}>
                    Conduct online tests & assignments
                  </p>
                  <p className={classes.EngagementDescription}>
                    Stay connected with students via chat
                  </p>
                  <p className={classes.EngagementDescription}>
                    Send regular notifications & updates
                  </p>
                </div> */}
						<p className={classes.EngagementText}>
							Students across India would be purchasing online courses from your app
						</p>
						<div className={classes.EngagementContainerFooter} onClick={this.handleNextClick}>
							<div class={classes.EngagementBounce}>
								<img width="24" height="24" alt="arrow" src={arrowUp} />
							</div>
							<p style={{ margin: '0px' }}>Swipe up to see more</p>
						</div>
					</div>
				</div>
			</Swipeable>
		);
	}
}

export default Engagement;

import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
// import backgroundSub from '../images/Mobile.svg';
import backgroundSub from '../images/Frame.png';
import background from '../images/Frame.webp';
import Image from 'react-image-webp';
// import Splash from "../Splash/Splash";
import Growth from './Growth';
import MyInstitute from '../images/MyInstituteIntro.svg';
import HelloScreen from './HelloScreen';
import LazyImage from './LazyImage';
import sound from '../images/audio.mp3';
class Intro extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isTellMoreClicked: false,
			name: '',
			imageLoaded: false
		};
	}
	componentDidMount() {
		const nam = localStorage.getItem('name');
		this.setState({ name: nam });
		window.scrollTo(0, 0);
		setTimeout(() => {
			this.setState({ imageLoaded: true });
		}, 800);
	}

	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextButton = () => {
		this.setState({ isTellMoreClicked: true });
	};

	handleTellMore = () => {};

	render() {
		const { isBackClicked, isTellMoreClicked, name, imageLoaded } = this.state;
		// const { name } = this.props;
		// const { name } = localStorage.getItem('name');
		return isBackClicked ? (
			<HelloScreen />
		) : isTellMoreClicked ? (
			<div>
				<audio src={sound} autoPlay />

				<Growth />
			</div>
		) : (
			<div className={classes.IntroContainer}>
				<button className={classes.IntroHeader} onClick={this.handleBackClicked}>
					<img src={back} alt="arrow" />
				</button>
				<div className={classes.IntroMobileContainer}>
					{imageLoaded ? (
						<div className={classes.IntroMobileText}>
							<img src={MyInstitute} alt="MyInstitute" style={{ marginRight: '12px' }} />
							{name}
						</div>
					) : (
						''
					)}
					<div>
						<img src={backgroundSub} style={{ width: '100%' }} />
						{/* <Image className={classes.IntroMobile} src={backgroundSub} webp={background} /> */}
						{/* <LazyImage className={classes.IntroSubBg} src={backgroundSub} webp={background} isIntro={1} /> */}
					</div>
				</div>
				<div className={classes.IntroFooterContainer}>
					{/* <button className={classes.IntroTellMore} onClick={this.handleTellMore}> Tell Me More</button> */}
					<button className={classes.IntroButton} onClick={this.handleNextButton}>
						Take me to the future!
					</button>
				</div>
			</div>
		);
	}
}

export default Intro;

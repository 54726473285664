import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
import backgroundSub from '../images/Illustration2.png';
import background from '../images/revenue.webp';
// import Image from "react-image-webp";
import Growth from './Growth';
import Engagement from './Engagement';
import arrowUp from '../images/arrowUp.svg';
import { Swipeable } from 'react-swipeable';
import LazyImage from './LazyImage';
import live from '../images/live.gif';
class Revenue extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextClick = () => {
		this.setState({ isNextClicked: true });
	};

	render() {
		const { isBackClicked, isNextClicked } = this.state;
		return isBackClicked ? (
			<Growth />
		) : isNextClicked ? (
			<Engagement />
		) : (
			<Swipeable
				onSwipedUp={() => {
					this.handleNextClick();
				}}
			>
				<div className={classes.RevenueContainer}>
					<div className={classes.RevenueInnerContainer}>
						<div className={classes.RevenueHeader}>
							<button className={classes.RevenueBackButton} onClick={this.handleBackClicked}>
								<img src={back} alt="back" />
							</button>
							{/* <button className={classes.RevenueTellMore}>Tell me more</button> */}
						</div>
						<div className={classes.RevenueBackgroudImg}>
							{/* <img className={classes.RevenueMainBg} src={backgroudMain} /> */}
							{/* <Image className={classes.RevenueSubBg}
              src={backgroundSub}
              webp={background}
              /> */}
							{/* <LazyImage
								className={classes.RevenueSubBg}
								src={backgroundSub}
								webp={background}
								// color={rgba(114, 175, 175, 0.16)}
              /> */}
							{/* <img src={live} /> */}
							<img src={live} className={classes.RevenueLive} />
							<img src={backgroundSub} style={{ width: '100%' }} />
						</div>
						<div className={classes.RevenueText}>
							Your live classes will be loved by students across India
						</div>
						{/* <div className={classes.RevenueContainerBody}>
							<p className={classes.RevenueBodyHeader}>Revenue Multiplied</p>
							<p className={classes.RevenueDescription}>Automatic reminders & receipts for students’ due fees</p>
							<p className={classes.RevenueDescription}>Easily sell your study material across India</p>
						
						</div> */}
						<div className={classes.RevenueContainerFooter} onClick={this.handleNextClick}>
							<div class={classes.RevenueBounce}>
								<img width="24" height="24" alt="arrow" src={arrowUp} />
							</div>
							<p style={{ margin: '0px' }}>Swipe up to see more</p>
						</div>
					</div>
				</div>
			</Swipeable>
		);
	}
}

export default Revenue;

import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/back.svg';
import myInstituteSmall from '../images/MyInstituteSmall.svg';
import classplus from '../images/Classplus-logo-small.svg';
import Brand from './Brand';
class Description extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	render() {
		const { isBackClicked } = this.state;
		return isBackClicked ? (
			<Brand />
		) : (
			<div className={classes.DescriptionContainer}>
				<div className={classes.DescriptionHeader} onClick={this.handleBackClicked}>
					<img src={back} alt="arrow" />
				</div>
				<div className={classes.DescriptionContent}>
					{/* <img src={myInstituteSmall} alt="myInstituteSmall" className={classes.MyInstitute} /> */}
					{/* <p className={classes.Heading}>Harsh Classes</p>
                <p className={classes.SubHeading}>Step into the future with</p> */}
					<p className={classes.DescriptionText}>Live the future with</p>
					<p className={classes.DescriptionText}>आओ भविष्य जियें</p>
					<p className={classes.DescriptionText}>सह भविष्यात जगतात</p>
					<p className={classes.DescriptionText}>સાથે ભવિષ્ય જીવો</p>
					<p className={classes.DescriptionText}>భవిష్యత్తుతో జీవించండి</p>
					<p className={classes.DescriptionText}>সাথে ভবিষ্যত বাঁচান</p>
					<p className={classes.DescriptionText}> चला भविष्य जगूया</p>
					<p className={classes.DescriptionText}>ਚਲੋ ਭਵਿੱਖ ਜੀਓ</p>
					<img src={classplus} alt="classplus" />
					{/* <button className={classes.DescriptionButton}>Tell me more!</button> */}
				</div>
			</div>
		);
	}
}

export default Description;

import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
import backgroundSub from '../images/Illustration4.png';
import background from '../images/teach.webp';
// import Image from "react-image-webp";
import Engagement from './Engagement';
import Brand from './Brand';
import arrowUp from '../images/arrowUp.svg';
import { Swipeable } from 'react-swipeable';
import money from '../images/money.gif';
import LazyImage from './LazyImage';
class Teach extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextClick = () => {
		this.setState({ isNextClicked: true });
	};

	render() {
		const { isBackClicked, isNextClicked } = this.state;
		return isBackClicked ? (
			<Engagement />
		) : isNextClicked ? (
			<Brand />
		) : (
			<Swipeable
				onSwipedUp={() => {
					this.handleNextClick();
				}}
			>
				<div className={classes.TeachContainer}>
					<div className={classes.TeachInnerContainer}>
						<div className={classes.TeachHeader}>
							<button className={classes.TeachBackButton} onClick={this.handleBackClicked}>
								<img src={back} alt="back" />
							</button>
							{/* <button className={classes.TeachTellMore}>Tell me more</button> */}
						</div>

						{/* <div className={classes.TeachContainerBody}>
							<p className={classes.TeachBodyHeader}>Teach Live in 30 seconds</p>
							<p className={classes.TeachDescription}>
								Help students study in a classroom like environment with regular Live Classes
Teach							</p>
							<p className={classes.TeachDescription}>You can get recorded lectures of Live classes Teachtoo!</p>
							
						</div> */}
						<div className={classes.TeachText}>You will double your income in the next 12 months</div>
						<div className={classes.TeachBackgroudImg}>
							{/* <img className={classes.TeachMainBg} src={backgroudMain} /> */}
							{/* <Image className={classes.TeachSubBg}
                      src={backgroundSub}
                      webp={background}
                      /> */}
							{/* <LazyImage
								className={classes.SubBg}
								src={backgroundSub}
								webp={background}
								// color={rgba(114, 175, 175, 0.16)}
							/> */}
							<img src={money} />
							<img src={backgroundSub} className={classes.TeachIllustration} />
						</div>
						<div className={classes.TeachContainerFooter} onClick={this.handleNextClick}>
							<div class={classes.TeachBounce}>
								<img width="24" height="24" alt="arrow" src={arrowUp} />
							</div>
							<p style={{ margin: '0px' }}>Swipe up to see more</p>
						</div>
					</div>
				</div>
			</Swipeable>
		);
	}
}

export default Teach;

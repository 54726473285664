import React, { Component } from 'react';
import classes from './index.module.css';
import back from '../images/backWhite.svg';
import backgroundSub from '../images/Illustration1.png';
import background from '../images/growth.webp';
import Image from 'react-image-webp';
import Intro from './Intro';
import Revenue from './Revenue';
import arrowUp from '../images/arrowUp.svg';
import { Swipeable } from 'react-swipeable';
import LazyImage from './LazyImage';
import download from '../images/download.gif';
import sound from '../images/audio.mp3';
class Growth extends Component {
	constructor() {
		super();
		this.state = {
			isBackClicked: false,
			isNextClicked: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleBackClicked = () => {
		this.setState({ isBackClicked: true });
	};

	handleNextClick = () => {
		this.setState({ isNextClicked: true });
	};

	render() {
		const { isBackClicked, isNextClicked } = this.state;
		return isBackClicked ? (
			<Intro />
		) : isNextClicked ? (
			<Revenue />
		) : (
			<Swipeable
				onSwipedUp={() => {
					this.handleNextClick();
				}}
			>
				{/* <audio src={sound} autoPlay /> */}
				<div className={classes.GrowthContainer}>
					<div className={classes.GrowthInnerContainer}>
						<div className={classes.GrowthHeader}>
							<button className={classes.GrowthBackButton} onClick={this.handleBackClicked}>
								<img src={back} alt="back" />
							</button>
							{/* <button className={classes.GrowthTellMore}>Tell me more</button> */}
						</div>
						<div className={classes.GrowthText}>Thousands of students will be downloading your app</div>
						<div className={classes.GrowthBackgroudImg}>
							{/* <Image
								className={classes.GrowthSubBg}
								src={backgroundSub}
								// webp={background}
              /> */}
							<img src={download} className={classes.GrowthDownload} />
							<img src={backgroundSub} style={{ width: '100%' }} />

							{/* <LazyImage
								className={classes.GrowthSubBg}
								src={backgroundSub}
								webp={background}
								// color={rgba(114, 175, 175, 0.16)}
							/> */}
						</div>
						{/* <div className={classes.GrowthContainerBody}>
              <p className={classes.GrowthBodyHeader}>Growth Multiplied</p>
              <p className={classes.GrowthDescription}>Teach more students</p>
              <p className={classes.GrowthDescription}>Increase your reach</p>
              <p className={classes.GrowthDescription}>
                Make your coaching a bigger brand
              </p>
            </div> */}
						<div className={classes.GrowthContainerFooter} onClick={this.handleNextClick}>
							<div class={classes.GrowthBounce}>
								<img width="24" height="24" alt="arrow" src={arrowUp} />
							</div>
							<p style={{ margin: '0px' }}>Swipe up to see more</p>
						</div>
					</div>
				</div>
			</Swipeable>
		);
	}
}

export default Growth;
